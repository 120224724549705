import React from 'react';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

const Testimonial = () => {
    const data = [
        {
            name:"Sadaf Anjum",
            designation:"Student",
            desc: "“Uday Kumar brother taught java programming which was a game-changer for me.His online lectures, combined with his deep knowledge and splendid coding skills made learning easier and rewarding too...“"

        },
        {
            name:"Prabhakaran haribaskar",
            designation:"Software Engineer",
            desc: "“Uday Kumar is an exceptional frontend developer with strong debugging skills and a keen eye for detail. In a short time, he advanced to lead the frontend team, demonstrating both technical expertise and leadership. His skill set spans beyond frontend to backend and system design, making him a versatile asset. Uday’s dedication and problem-solving abilities have been invaluable to our projects, and he consistently drives results with excellence. “",
            ProfilePic:"https://media.licdn.com/dms/image/v2/C5103AQFIog55LhYYOg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1528859133628?e=1736380800&v=beta&t=AB7K7j0gGLgSqUehPf7FcbKtv0CpBL5TUcxh-VHB0wI"
        }
    ]
    const data2 = [
        {
            name:"Jyotsna Vijaya Naga Sri",
            designation:"Student",
            desc: "“His technical knowledge and teaching skills are soo good. I feel like a real time experience.“"
        },
        {
            name:"Divya Prathyusha",
            designation:"Student",
            desc: "“Excellent teaching.After taking this java classes my java knowledge has improved a lot. I got real time experience through this course. “"
        },
    ]
    const data3 = [
        {
            name:"Sravani Vedullapalli",
            designation:"Student",
            desc: "“I thoroughly enjoyed the class. In this course I got clear knowledge about java programming.“"
        },
        {
            name:"Pranathi Durga",
            designation:"Student",
            desc: "“A significant influencer and a man with kind heart,   good exposure and with many more great abilities. Thinks share everything to many other with no ego .“"
        }
    ]
    const data4 = [
        {
            name:"Mounika C H",
            designation: "Software Engineer",
            desc:"“Uday has been a key part of improving our coding skills and debugging abilities. His extensive knowledge and expertise in programming have greatly influenced our understanding of how to approach tasks effectively. He has a remarkable talent for explaining complex concepts clearly, guiding us through the debugging process, and helping us find solutions to every challenge. His passion for coding is inspiring, making us eager to dive deeper into our work. Thanks to his support, we’ve learned to analyze each line of code and truly understand how everything functions. I am grateful for his guidance and the positive impact he has had on our team.“",
            ProfilePic:"https://media.licdn.com/dms/image/v2/D5603AQFrdyqorYLWlQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1672803760701?e=1735776000&v=beta&t=rkUKjHaiElp8vAb23W3ejPqmKJUKuO-9cC2pX6jEWM4"
        },
        {
            name:"Satya Lalitha Gayathri",
            designation:"Student",
            desc: "“I had zero experience with java before,but this course made it approachable and enjoyable.“"
        }
    ]
    return (
        <div className='bg-gradient-to-r from-slate-50 to-violet-50 px-[5%] py-8'>
            <h1 className='text-xl text-blue-700 text-center my-2'>Testimonials</h1>
            <h2 className='text-3xl font-semibold text-center mb-8 w-full laptop:w-1/3 mx-auto'>We have worked with thousands of amazing people</h2>

            {/* Main container for testimonials */}
            <div className='flex gap-8 flex-col laptop:flex-row justify-center w-full'>
                {/* Column 1 */}
                <div className='flex flex-col gap-4 w-full laptop:w-1/4'>
                    {data.map((_, i) => (
                        <div key={i} className='border-2 border-[#E5DCEA] rounded-2xl p-6 bg-white mb-4 shadow-lg shadow-cyan-200'>
                            <p className='text-justify'>{_.desc}</p>
                            <div className='flex items-center gap-4 pt-4'>
                                <Avatar alt="Remy Sharp" src={_.ProfilePic} />
                                <div className='flex flex-col'>
                                    <h1 className='font-semibold'>{_.name}</h1>
                                    <p className='text-sm'>{_.designation}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Column 2 - Wide Testimonial */}
                <div className='flex flex-col gap-4 w-full laptop:w-2/4'>
                    <div className='border-2 border-[#E5DCEA] rounded-2xl bg-white shadow-lg shadow-cyan-200'>
                        <p className='p-6 text-justify'>
                            “I began learning Python in May 2023 under the guidance of Uday, a software professional with a true passion for teaching. His dedication and commitment to teaching extend beyond words; it reflects in his actions and unique teaching style. With Uday's support, I progressed from foundational Python skills to tackling the MERN stack—a journey that has been both challenging and deeply rewarding. He emphasizes real-world application through hands-on projects, ensuring that each lesson is grounded in practical experience. I feel incredibly fortunate to have him as a mentor, and his mentorship has made a significant impact on my learning journey.“
                        </p>
                        <Divider />
                        <div className='flex justify-between items-center p-4'>
                            <div className='flex items-center gap-4 mt-2'>
                                <Avatar alt="Remy Sharp" src="https://media.licdn.com/dms/image/v2/D5635AQFR-NIYHO7xAQ/profile-framedphoto-shrink_800_800/profile-framedphoto-shrink_800_800/0/1719355787763?e=1741179600&v=beta&t=qQyC2ri_BLObWURQyJSnsp5oA_yqWU1o15UcPx5muOQ" />
                                <div className='flex flex-col'>
                                    <h1 className='font-semibold'>Nitya Sunkara</h1>
                                    <p className='text-sm'>SDET Intern</p>
                                </div>
                            </div>
                            <img className='h-[40px]' src='https://lirp.cdn-website.com/148aeb9c/dms3rep/multi/opt/RocketLawyer%28R%29%2BcircleR_logo-RGB-red_1000px+%281%29-640w.png' />
                        </div>
                    </div>
                    <div className='flex gap-4 w-full'>
                        <div className='flex flex-col gap-4 w-1/2'>
                            {data2.map((_, i) => (
                                <div key={i} className='border-2 border-[#E5DCEA] rounded-xl p-4 bg-white p-6 shadow-lg shadow-cyan-200'>
                                    <p className='text-justify'>{_.desc}</p>
                                    <div className='flex items-center gap-4 mt-2 pt-4'>
                                        <Avatar alt="Remy Sharp" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80" />
                                        <div className='flex flex-col'>
                                        <h1 className='font-semibold'>{_.name}</h1>
                                        <p className='text-sm'>{_.designation}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-col gap-4 w-1/2'>
                            {data3.map((_, i) => (
                                <div key={i} className='border-2 border-[#E5DCEA] rounded-xl p-4 bg-white p-6 shadow-lg shadow-cyan-200'>
                                    <p className='text-justify	'>{_.desc}</p>
                                    <div className='flex items-center gap-4 mt-2 pt-4'>
                                        <Avatar alt="Remy Sharp" src={_.ProfilePic} />
                                        <div className='flex flex-col'>
                                        <h1 className='font-semibold'>{_.name}</h1>
                                        <p className='text-sm'>{_.designation}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Column 3 */}
                <div className='flex flex-col gap-4 w-full laptop:w-1/4'>
                    {data4.map((_, i) => (
                        <div key={i} className='border-2 border-[#E5DCEA] rounded-xl p-6 shadow-lg shadow-cyan-200 bg-white'>
                            <p className='text-justify	'>{_.desc}</p>
                            <div className='flex items-center gap-4 mt-2 pt-4'>
                                <Avatar alt="Remy Sharp" src={_.ProfilePic} />
                                <div className='flex flex-col'>
                                    <h1 className='font-semibold'>{_.name}</h1>
                                    <p className='text-sm'>{_.designation}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
