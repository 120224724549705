import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import About from './Components/About/About';
import Blog from './Components/Blog';
import ContactUs from './Components/Contact/ContactUs';
import Error404 from './Components/Error404';
import Admin from './Pages/Admin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RequireAuth from './CustomHooks/RequireAuth';
import AdminDashboard from './Pages/AdminDashboard';
import { AuthProvider } from './CustomHooks/AuthContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error404 />,
    children: [
      {
        path: "about",
        element: <About />
      },
      {
        path: "blog",
        element: <Blog />
      },
      {
        path: "contact-us",
        element: <ContactUs />
      },
      {
        path: "home",
        element: <App />
      }
    ],
  },
  {
    path: "/admin",
    element: <RequireAuth><AdminDashboard /></RequireAuth>
  },
  {
    path: "/adminlogin",
    element: <Admin />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId='906046445776-m2na643fcvbvisk267q38ela7at4f2i8.apps.googleusercontent.com' >
    <React.StrictMode>
      <AuthProvider>
      <RouterProvider router={router} />
      </AuthProvider>
      {/* <App /> */}
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
