import React from 'react'
import { useAuth } from '../../CustomHooks/AuthContext';

const AdminDashboard = () => {
  const { logOut } = useAuth();
  return (
    <div>
      <button onClick={logOut}>Log Out</button>
    </div>
  )
}

export default AdminDashboard