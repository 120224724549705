import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import React from "react";

export default function RequireAuth({ children }) {
    const { userData, loading } = useAuth();

    if (loading) return <div>Loading...</div>; // Prevents early redirection

    return userData?.email === "udaykumarvalapudasu@gmail.com" && userData.id === "109185747593517758860" ? children : <Navigate to="/adminlogin" replace />;
}
