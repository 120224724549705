import React, { useEffect, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useAuth } from '../../CustomHooks/AuthContext';
const Admin = () => {
    const [user, setUserData] = useState([]);
    const { login, userData } = useAuth();
    // const login = useGoogleLogin({
    //     onSuccess: (codeResponse) => setUserData(codeResponse),
    //     onError: (error) => console.log('Login Failed:', error)
    // });
    return (
        <div className='bg-white p-2 h-[100vh] w-[100vw] flex items-center justify-center flex-col gap-2'>
            <h1 className='text-2xl font-bold text-black text-center'>Sign in to Admin Portal</h1>
            <button className='border-2 border-black p-2 mx-auto block flex items-center rounded-lg font-[600]' onClick={login}><img className='w-[35px]' src='https://logos-world.net/wp-content/uploads/2020/09/Google-Symbol.png' alt='Google Logo' /> Google</button>
            {/* {userData && userData.email === "udaykumarvalapudasu@gmail.com" ? <h1>Admin</h1> : <h1>Not Admin</h1>}
            <button className='border-2 border-black p-2' onClick={login}>Sign in with Google 🚀 </button> */}
        </div>
    )
}

export default Admin